import React from 'react';
import {
	Card, CardContent, makeStyles, Button, CardActions, CardMedia, CardHeader,
} from '@material-ui/core';
import clsx from 'clsx';
import FadeIn from '../hooks/FadeIn';
import Marked from './Marked';
import aboutPng from '../assets/about.png';

const SMUJ_INFO = `I'm an application engineer and I make things`

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1),
		marginTop: 30 + theme.spacing(1),
		overflow: 'visible',
		'& a': {
			textDecoration: 'inherit',
		},
	},
	media: {
		height: 100,
		width: 100,
		marginLeft: 'auto',
		marginRight: theme.spacing(2),
		marginTop: -20,
		marginBottom: -80,
		borderRadius: theme.spacing(1),
		boxShadow: theme.shadows[4],
		transform: 'translate(0px, -10px)',
	},
	header: {
		paddingRight: 100 + theme.spacing(3),
		paddingBottom: 0,
	},
	content: {
		padding: theme.spacing(1),
		paddingTop: 0,
	}
}));

interface SmujInfoProps {
	className?: string;
}

export default function SmujInfo(props: SmujInfoProps): JSX.Element {
	const { className } = props;

	const classes = useStyles();

	return (<Card className={clsx(classes.root, className)}>
		<FadeIn noFlash>
			<CardMedia
				className={classes.media}
				image={aboutPng}
			/>
		</FadeIn>
		<CardHeader
			className={classes.header}
			title="Michael Szmadzinski"
			subheader="About"
		/>
		<CardContent className={classes.content}>
			<Marked md={SMUJ_INFO} />
		</CardContent>
		<CardActions>
			<div style={{ flex: '100%' }} />
			<FadeIn>
				<a
					href="mailto:smujmaiku@gmail.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>Email</Button>
				</a>
			</FadeIn>
			<FadeIn>
				<a
					href="https://twitter.com/smujmaiku"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>Twitter</Button>
				</a>
			</FadeIn>
			<FadeIn>
				<a
					href="https://www.linkedin.com/in/smujmaiku/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>LinkedIn</Button>
				</a>
			</FadeIn>
			<FadeIn>
				<a
					href="https://github.com/smujmaiku/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>Github</Button>
				</a>
			</FadeIn>
			<FadeIn>
				<a
					href="https://www.npmjs.com/~smujmaiku/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>NPM</Button>
				</a>
			</FadeIn>
		</CardActions>
	</Card>);
}