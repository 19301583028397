import React from 'react';
import clsx from 'clsx';
import FadeIn, { FadeInGroup } from '../hooks/FadeIn';
import { makeStyles } from '@material-ui/core';
import SmujInfo from '../components/SmujInfo'
import ProjectCard from '../components/ProjectCard'
import unMagicEyePng from '../assets/unmagiceye.png'
import aslfriendPng from '../assets/aslfriend.png'
import borealChaserPng from '../assets/borealchaser.png'
import futbolGamePng from '../assets/futbolgame.png'
import qyeGamePng from '../assets/qyegame.png'
import crystalPipePng from '../assets/crystalpipenecklace.png'

const useStyles = makeStyles((theme) => ({
	root: {},
	info: {
		maxWidth: 900,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	projects: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
		maxWidth: 1300,
		margin: theme.spacing(1, 'auto'),
	}
}));

interface HomeProps {
	className: string | null,
}

export default function Home(props: HomeProps): JSX.Element {
	const { className } = props

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<FadeInGroup delay={100} time={200} margin={-150}>
				<SmujInfo className={classes.info} />
				<div className={classes.projects}>
					<FadeIn>
						<ProjectCard
							image={aslfriendPng}
							title="ASL Friend"
							body="This is a free resource for **American Sign Language** students to review and learn the language."
							url="https://aslfriend-5f783.web.app/"
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							image={unMagicEyePng}
							title="Un-Magic Eye"
							body="I'm awful at magic eyes. I made this to let me see a few."
							url="https://unmagiceye.web.app/"
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							image={borealChaserPng}
							title="Boreal Chaser"
							body="A resource for photographers and junior astronomers to view the Aurora Borealis."
							url="https://borealchaser.web.app/"
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							image={qyeGamePng}
							title="Qye Game"
							body="Part of my **Project-a-Month Expiriment**, Qye is a puzzle adventure game. Qye is inspired by a game from the from the early 90s called [Kye by Colin Garbutts](http://kye.me.uk/). Please freely enjoy playing community worlds and creating your own."
							url="https://qyegame.web.app/"
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							image={futbolGamePng}
							title="FutBol Game(Offline)"
							body="My first step into canvas, nodejs and websockets as game frameworks. Futbol is a simple click and kick soccer webapp game. I've kicked this project around for over two years since it is such a great example of replacing flash. While this is entirely incomplete, I plan to pick it back up with my Project-a-Month Experiment and polish this gem."
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							image={crystalPipePng}
							title="Crystal Pipe Necklace"
							body="A variety of hand crafted crystal pipes and crystal pipe necklaces to help aide you on your spiritual journey. Please check back often as we are always adding beautiful new pieces to the shop!"
							url="https://www.crystalpipenecklace.com/"
						/>
					</FadeIn>
					<FadeIn>
						<ProjectCard
							title="Bevco"
							body="Bevco is a family-owned and operated company founded in 1947, currently headquartered in Waukesha, WI"
							url="https://www.bevco.com/"
						/>
					</FadeIn>
				</div>
			</FadeInGroup>
		</div>
	);
}
