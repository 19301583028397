import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { AnalyticsProvider } from './hooks/analytics';
import TopMenu from './views/TopMenu';
import Home from './views/Home';

interface ProviderProps {
	children: JSX.Element
}

export function Providers({ children }: ProviderProps): JSX.Element {
	return [
		(child: JSX.Element): JSX.Element => (<AnalyticsProvider>{child}</AnalyticsProvider>),
		(child: JSX.Element): JSX.Element => (<Router>{child}</Router>),
	].reverse().reduce(
		(child: JSX.Element, provider: (child: JSX.Element) => JSX.Element): JSX.Element => provider(child),
		children,
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 400,
		margin: 'auto',
	},
	status: {
		top: 0,
	},
	view: {},
	viewGutter: {
		margin: theme.spacing(2),
	},
	menu: {
		top: 'auto',
		bottom: 0,
	},
}));

export default function App(): JSX.Element {
	const classes = useStyles();

	return (
		<Providers>
			<div className={classes.root}>
				<TopMenu />
				<Switch>
					<Route path="/" exact>
						<Home className={classes.view} />
					</Route>
					<Route>
						<Redirect to="/" />
					</Route>
				</Switch>
				<div className={classes.viewGutter} />
			</div>
		</Providers>
	);
}
