import React from 'react';
import clsx from 'clsx';
import {
	Card, CardContent, makeStyles, Button, CardActions, CardMedia, CardHeader,
} from '@material-ui/core';
import Marked from './Marked';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 400,
		margin: theme.spacing(1),
	},
	media: {
		height: 150,
	},
	header: {
		paddingBottom: 0,
	},
	content: {
		padding: theme.spacing(1),
		paddingTop: 0,
	},
	actions: {
		'& a': {
			textDecoration: 'none'
		}
	}
}))

interface ProjectCardsProps {
	className?: string;
	image?: string;
	title: string;
	body: string;
	url?: string;
	urlLabel?: string;
}

export default function ProjectCard(props: ProjectCardsProps): JSX.Element {
	const {
		className,
		image,
		title,
		body,
		url,
		urlLabel,
	} = props;

	const classes = useStyles();

	return <Card className={clsx(classes.root, className)}>
		<CardMedia
			className={classes.media}
			image={image}
		/>
		<CardHeader
			className={classes.header}
			title={title}
		/>
		<CardContent className={classes.content}>
			<Marked md={body} />
		</CardContent>
		<CardActions className={classes.actions}>
			{url && <a href={url}>
				<Button>{urlLabel || title}</Button>
			</a>
			}
		</CardActions>
	</Card>
}