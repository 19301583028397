import React, { useEffect, useContext, createContext, useState } from 'react';
import firebase from 'firebase';
import 'firebase/analytics';

const firebaseConfig = {
	apiKey: "AIzaSyCHKNV7eVHV499gII6qMv-7IF5eZbmtQ2o",
	authDomain: "smujdev.firebaseapp.com",
	databaseURL: "https://smujdev.firebaseio.com",
	projectId: "smujdev",
	storageBucket: "smujdev.appspot.com",
	messagingSenderId: "696701485132",
	appId: "1:696701485132:web:c98e84f4e93829160bf4e9",
	measurementId: "G-K2P3WM3HSQ"
};

export const analyticsContext = createContext<firebase.analytics.Analytics | null>(null);

export default function useAnalytics(): firebase.analytics.Analytics | null {
	return useContext(analyticsContext);
}
interface ProviderProps {
	children: JSX.Element | JSX.Element[];
}

export function AnalyticsProvider(props: ProviderProps): JSX.Element {
	const { children } = props;

	const [value, setValue] = useState<firebase.analytics.Analytics | null>(null);

	useEffect(() => {
		firebase.initializeApp(firebaseConfig);
		const analytics = firebase.analytics();
		setValue(analytics);
		analytics.logEvent('init');
	}, []);

	return (
		<analyticsContext.Provider value={value}>
			{children}
		</analyticsContext.Provider>
	);
}