import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import marked from 'marked';

export const defaultOpts = {
	breaks: true,
	gfm: true,
	smartypants: true,
	// sanitize: true,
	renderer: Object.assign(new marked.Renderer(), {
		// link: (href, title, text) => `<span style="color: ${getColorLevel(href, title || '500')}">${text}</span>`,
		heading: (text: string, level: number) => {
			const invLevel = 6 - level;
			return `<h${invLevel}>${text}</h${invLevel}>`;
		},
		// image: (src, title, text) => {
		// 	if (src.indexOf('/') < 0) return `<Icon>${src}</Icon>`;
		// 	return marked.Renderer.prototype.image.call({ options: {}, ...this }, src, title, text);
		// },
	}),
};

/**
 * Use Marked as hook
 * @param {string} md
 * @param {Object?} opts static
 */
export function useMarked(md: string, opts: marked.MarkedOptions = defaultOpts): string {
	const [mdOpts] = useState(opts);

	return useMemo<string>(
		() => marked(md || '', mdOpts),
		[md, mdOpts],
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& h1, & h2, & h3, & h4, & h5, & h6, & p, & blockquote': {
			margin: theme.spacing(1),
		},
		'& h1': { fontSize: '3em' },
		'& h2': { fontSize: '2.6em' },
		'& h3': { fontSize: '2.2em' },
		'& h4': { fontSize: '1.9em' },
		'& h5': { fontSize: '1.6em' },
		'& h6': { fontSize: '1.3em' },
		'& blockquote': { textAlign: 'center' },
		'& blockquote > blockquote': { textAlign: 'right' },
		'& table': { width: '100%' },
		'& img': {
			height: '1.1em',
			marginBottom: '-0.2em',
		},
	},
}));

interface MarkedProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	className?: string;
	md: string;
	mdOpts?: marked.MarkedOptions;
}

export default function Marked(props: MarkedProps): JSX.Element {
	const {
		className,
		md,
		mdOpts,
		...otherProps
	} = props;

	const html = useMarked(md, mdOpts);
	const classes = useStyles();

	/* eslint-disable react/no-danger,react/jsx-props-no-spreading */
	return (
		<div
			{...otherProps}
			className={clsx(classes.root, className)}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
}
