import React from 'react';
import clsx from 'clsx';
import {
	makeStyles, AppBar, Toolbar, IconButton, Typography, Collapse
} from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#eee',
		color: '#111',
	},
	header: {
		flex: '100% 1 1',
		margin: theme.spacing(0, 1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		fontSize: 36,
		fontWeight: 'bold',
		'& > *': {
			flex: '100% 1 1',
		},
	},
	rootSpacer: {
		margin: 0,
	},
	ringingButton: {
		width: '100%',
	},
	startChip: {
		marginRight: theme.spacing(1),
	},
	endChip: {
		marginLeft: theme.spacing(1),
	},
}));

interface TopMenuProps {
	className?: string,
}

export default function TopMenu(props: TopMenuProps): JSX.Element {
	const { className } = props;

	const classes = useStyles();

	const location = useLocation();
	const show = location.pathname !== '/';

	return (
		<>
			<AppBar
				className={clsx(className, classes.root)}
				position="fixed"
			>
				<Collapse in={show}>
					<Toolbar>
						<Link to="/">
							<IconButton>
								<HomeIcon />
							</IconButton>
						</Link>
						<Typography className={classes.header} variant="h3" component="h4">
							Michael Szmadzinski
						</Typography>
					</Toolbar>
				</Collapse>
			</AppBar>
			<Collapse in={show}>
				<Toolbar
					className={classes.rootSpacer}
				/>
			</Collapse>
		</>
	);
}
